<template>
	<div class="xcx-module-attr" @click.stop>
		<AttrConTopimg :attr="attr" :moduleKey="moduleKey" v-if="moduleKey== 'name'" />			<!-- 企业名称 -->
		<AttrNav :attr="attr" :moduleKey="moduleKey" v-if="moduleKey == 'tools'" />				<!-- 工具列表 -->
		<AttrDuo :attr="attr" :moduleKey="moduleKey" v-if="moduleKey == 'banner'" />			<!-- 轮播广告 -->
		<AttrArticle :attr="attr" :moduleKey="moduleKey" v-if="moduleKey == 'text'" />			<!-- 文章列表 -->
		<AttrTuijian :attr="attr" :moduleKey="moduleKey" v-if="moduleKey == 'articles'" />		<!-- 富文本 -->
		<AttrButton :attr="attr" :moduleKey="moduleKey" v-if="moduleKey == 'button'" />			<!-- 跳转按钮 -->
	</div>
</template>

<script>
import AttrConTopimg from '../attr/con_topimg.vue';
import AttrNav from '../attr/nav.vue';
import AttrDuo from '../attr/duo.vue';
import AttrTuwen from '../attr/tuwen.vue';
import AttrTuijian from '../attr/tuijian.vue';
import AttrButton from '../attr/button.vue';
import AttrArticle from '../attr/article.vue';
export default {
	name: 'Attr',
	props: ['attr','moduleKey'], 
	components: { AttrNav, AttrDuo, AttrTuwen, AttrTuijian, AttrConTopimg, AttrButton, AttrArticle},
	methods:{
	}
};
</script>
