<template>
	<div>
		<ul class="module-attr-tab">
			<li>组件设置</li>
			<span>工具栏</span>
		</ul>
		<!--基础设置-->

		<div class="att-con-box">
			<div class="attr-nav-item" v-for="(item, index) in attr" :key="index">
				<div class="ht">
					<div class="t" :class="item.listShow ? 'on' : ''" @click="$util.openAttr(attr,index)">
						工具{{ index + 1 }}
						<span class="el-icon-arrow-down"></span>
					</div>
					<div class="opt">
						<el-button type="primary" v-if="index != 0" @click="up(attr, index)" icon="el-icon-arrow-up" circle></el-button>
						<el-button type="primary" v-if="index != attr.length - 1" @click="down(attr, index)" icon="el-icon-arrow-down" circle></el-button>
						<el-button type="danger" @click="navDelLink(index)" icon="el-icon-delete" circle></el-button>
					</div>
				</div>
				<transition name="down">
					<div class="hm" v-show="item.listShow">
						<AttrText :item="item" :moduleKey="moduleKey"/>
						<AttrLink :link="item" :ref="'link_'+index" :moduleKey="moduleKey" />
						<AttrIcon :icon="item" />
					</div>
				</transition>
			</div>
			<div class="attr-nav-add" v-if="attr.length < 15" @click="addNavList">
				<p class="nav_add_btn">添加工具</p>
				最少5个，最多添加15个
			</div>
		</div>
		<el-button class="link_fix_btn" type="primary" round @click="navSave">提交</el-button>
	</div>
</template>

<script>
import AttrText from './text.vue';
import AttrIcon from './icon.vue';
import AttrLink from './link.vue';
import module from '../util/module.js';
export default {
	name: 'AttrNav',
	props: ['attr','moduleKey'],
	components: { AttrText, AttrIcon, AttrLink},
	data() {
		return {};
	},
	created(){},

	methods: {
		//添加导航
		addNavList() {
			this.$util.addItem(this.attr,module.moduleNav);
		},
		//上移
		up(arr, index) {
			arr.splice(index - 1, 0, arr[index]);
			arr.splice(index + 1, 1);
		},
		//下移
		down(arr, index) {
			arr.splice(index + 2, 0, arr[index]);
			arr.splice(index, 1);
		},
		// 确定按钮获取页面数据
		navSave(){
			let that = this;
			for(var i = 0; i < that.attr.length; i++){
				if(that.attr[i].toolsName == ''){
					that.$message.warning('工具栏模块【工具' + (i+1) + '】的工具名称不能为空');
					return;
				}else if(that.attr[i].toolsImg == ''){
					that.$message.warning('工具栏模块【工具' + (i+1) + '】展示图片不能为空');
					return;
				}else{
					if(that.$refs[`link_${i}`][0].linkType == true){
						if(that.attr[i].jumpUrl == ''){
							that.$message.warning('工具栏模块【工具' + (i+1) + '】设置跳转外链类型的外链地址不能为空');
							return;
						}else{
							that.attr[i].appId = '';
							that.attr[i].indexUrl = '';
						}
					}else{
						if(that.attr[i].appId == ''){
							that.$message.warning('工具栏模块【工具' + (i+1) + '】设置跳转小程序类型的APPID不能为空');
							return;
						}else if(that.attr[i].indexUrl == ''){
							that.$message.warning('工具栏模块【工具' + (i+1) + '】设置跳转小程序类型的首页地址不能为空');
							return;
						}else{
							that.attr[i].jumpUrl = '';
						}
					}
				}
			}
			that.$message.success('该模块已提交');
		},
		// 删除列表项
		navDelLink(index){
			if(this.attr.length > 5){
				this.attr.splice(index, 1);
			}else{
				this.$message.warning('工具栏最低5个');
			}
		}
	}
};
</script>

<style>
.att-con-box{
	box-sizing: border-box;
	padding-bottom: 100px;
}
.nav_add_btn{
	background-color: #46A1FF;
	color: white;
	box-sizing: border-box;
	padding: 5px 10px;
	font-size: 12px;
	text-align: center;
	border-radius: 4px;
	margin-right: 5px;
}
.link_fix_btn{
	position: fixed;
	bottom: 20px;
	z-index: 3;
	right: 100px;
	width: 200px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	color: white;
	border-radius: 50px;
	text-align: center;
	cursor: pointer;
	border: none;
	padding: 0 !important;
}
</style>