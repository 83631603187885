<template>
	<div>
		<ul class="module-attr-tab">
			<li>组件设置</li>
			<span>文章列表</span>
		</ul>
		<!--基础设置-->
		<div class="att-con-box">
			<div class="attr-nav-item">
				<div class="attr-nav-item" v-for="(item, index) in attr" :key="index">
					<div class="ht">
						<div class="t" :class="item.listShow ? 'on' : ''" @click="$util.openAttr(attr,index)">
							文章{{ index + 1 }} 
							<span class="el-icon-arrow-down"></span>
						</div>
						<div class="opt" v-if="attr.length > 1">
							<el-button type="primary" v-if="index != 0" @click="up(attr, index)" icon="el-icon-arrow-up" circle></el-button>
							<el-button type="primary" v-if="index != attr.length - 1" @click="down(attr, index)" icon="el-icon-arrow-down" circle></el-button>
							<el-button type="danger" @click="attr.splice(index, 1)" icon="el-icon-delete" circle></el-button>
						</div>
					</div>
					
					<transition name="down">
						<div class="hm" v-show="item.listShow">
							<div class="item c">
								<div class="tit">文章标题：</div>
								<div class="input flex">
									<input type="text" placeholder="请输入文章标题" maxlength="100" v-model="item.articleTitle" class="intxt" />
								</div>
							</div>
							<div class="item">
								<div class="tit">文章来源：</div>
								<div class="input">
									<div class="input-line"> 
										<div class="sp1" @click="item.articleType = 1">
											<div class="sp_img" :class="item.articleType == 1  ? 'on' : ''"><img src="../assets/gou.png" alt=""></div>
											<span class="sp_tip">跳转链接</span>
										</div>
										<div class="sp1" @click="item.articleType = 2">
											<div class="sp_img" :class="item.articleType == 2 ? 'on' : ''"><img src="../assets/gou.png" alt=""></div>
											<span class="sp_tip">站内编辑</span>
										</div>
									</div>
								</div>
							</div>
							<AttrLink v-show="item.articleType == 1" :ref="'link_'+index" :link="item" />
							<avue-ueditor v-model="item.articleInfo"  :options="options" v-if="item.articleType == 2"></avue-ueditor>
						</div>
					</transition>
				</div>
				<div class="attr-nav-add" @click="addNavList">
					<p class="nav_add_btn">添加文章</p>
				</div>
			</div>
		</div>
		<el-button class="link_fix_btn" type="primary" round @click="articleSave">保存</el-button>
	</div>
</template>

<script>
import AttrText from './text.vue';
import AttrImg from './img.vue';
import AttrLink from './link.vue';
import AttrBase from './base.vue';
import module from '../util/module.js';

export default {
	name: 'AttrTuijian',
	props: ['attr'],
	components: { AttrText, AttrImg, AttrLink, AttrBase,},
	data() {
		return {
			options: {
				action:  "api/web/tool/file/upload",
				headers: {
					Authorization: this.$store.state.user.Token
				},
				customConfig: {},
				props: {
					url: "path",
					res: "data",
					name: 'path',
				}
            },
		};
	},
	created(){
		console.log('articleAttr');
		console.log(this.attr);
	},
	methods: {
		//添加
		addNavList() {
			this.$util.addItem(this.attr,module.moduleArc);
		},
		//上移
		up(arr, index) {
			arr.splice(index - 1, 0, arr[index]);
			arr.splice(index + 1, 1);
		},
		//下移
		down(arr, index) {
			arr.splice(index + 2, 0, arr[index]);
			arr.splice(index, 1);
		},
		// 确定按钮获取页面数据
		articleSave(){
			let that = this;
			for(var i = 0; i < that.attr.length; i++){
				if(that.attr[i].articleTitle == ''){
					that.$message.warning('文章列表模块【文章' + (i+1) + '】的文章标题不能为空');
					return;
				}else if(that.attr[i].articleType == 1){
					if(that.$refs[`link_${i}`][0].linkType == true){
						if(that.attr[i].jumpUrl == ''){
							that.$message.warning('文章列表模块【文章' + (i+1) + '】设置跳转外链类型的外链地址不能为空');
							return;
						}else{
							that.attr[i].appId = '';
							that.attr[i].indexUrl = '';
						}
					}else{
						if(that.attr[i].appId == ''){
							that.$message.warning('文章列表模块【文章' + (i+1) + '】设置跳转小程序类型的APPID不能为空');
							return;
						}else if(that.attr[i].indexUrl == ''){
							that.$message.warning('文章列表模块【文章' + (i+1) + '】设置跳转小程序类型的首页地址不能为空');
							return;
						}else{
							that.attr[i].jumpUrl = '';
						}
					}
				}else if(that.attr[i].articleType == 2){
					if(that.attr[i].articleInfo == ''){
						that.$message.warning('文章列表模块【文章' + (i+1) + '】站内内容不能为空');
						return;
					}
				}
			}
			that.$message.success('该模块已提交');
		},
	}
};
</script>

<style></style>
