<template>
	<div>
		<ul class="module-attr-tab">
			<li v-for="(item, index) in tab" :class="cur === index ? 'on' : ''" :key="index" @click="cur = index">{{ item.name }}</li>
		</ul>
		<!--基础设置-->
		<template v-if="cur == 0">
			<div class="attr-con-item">元件设置</div>
			<div class="att-con-box">
				<div class="attr-nav-item">
					<div class="att-con-pd">
						<AttrText :text="attr.title" :ispagetitle="true"/>
						<AttrText :text="attr.fh" :edit="false" />
						<AttrLink :link="attr.link" />					
					</div>
				</div>
			</div>
		</template>
		<!--样式设置-->
		<template v-if="cur == 1">
			<div class="att-con-box">
				<div class="att-con-pd">
					<AttrBase :base="attr.base" />
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import AttrText from './text.vue';
import AttrLink from './link.vue';
import AttrBase from './base.vue';
import module from '../util/module.js';
export default {
	name: 'AttrConHead',
	props: ['attr'],
	components: { AttrText, AttrLink, AttrBase },
	data() {
		return {
			tab: [{ name: '基础设置', content: '' }, { name: '样式设置', content: '' }],
			cur: 0
		};
	},

	methods: {		
	}
};
</script>

<style></style>
