<template>
	<div>
		<div class="item c">
			<div class="tit">{{ moduleKey == 'tools' ? '工具名称：' : moduleKey == 'articles' ? '文章标题' : moduleKey == 'button' ? '按钮名称' : ''}}</div>
			<div class="input flex">
				<input v-if="moduleKey == 'tools'" type="text" placeholder="请输入工具名称" maxlength="100" v-model="item.toolsName" class="intxt" />
				<input v-if="moduleKey == 'articles'" type="text" placeholder="请输入文章标题" maxlength="100" v-model="item.toolsName" class="intxt" />
				<input v-if="moduleKey == 'button'" type="text" placeholder="请输入按钮名称" maxlength="100" v-model="item.toolsName" class="intxt" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AttrText',
	props: {
		item: { type: Object },
		moduleKey: { type: String},
		ispagetitle:{ default: false}, //是否标题
	},
	data() {
		return {
			text:'',
			fontSizeList: [
				{ label: '12px', value: 12 },
				{ label: '14px', value: 14 },
				{ label: '16px', value: 15 },
				{ label: '18px', value: 18 },
				{ label: '20px', value: 20 },
				{ label: '22px', value: 22 },
				{ label: '24px', value: 24 },
				{ label: '30px', value: 30 }
			]
		};
	},
	watch: {
		text:{
			handler:function(newv,oldv){
			},
			deep:true, 
		}
	},
	mounted() {
		
	}
};
</script>
