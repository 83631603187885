<template>
	<div id="module">
		<section class="section-module" v-for="(element, index) in moduleKeyList" :key="index">
			<div class="xcx-module-item" :class="selectIndex === index ? 'on' : ''" @click.stop="moduleClick(index)" @mouseenter="mouseovers(index)" @mouseleave="mouseout">		
				<template v-if="!prev">
					<span class="xline"></span>
					<span class="xline"></span>
					<span class="xline"></span>
					<span class="xline"></span>
				</template>
				<conTopimg :module="moduleObject[element]" :moduleKey="element" v-if="element == 'name'" />			<!-- 企业名称 -->
				<moduleNav :module="moduleObject[element]" :moduleKey="element" v-if="element == 'tools'"/>			<!-- 工具列表 -->
				<moduleDuo :module="moduleObject[element]" :moduleKey="element" v-if="element == 'banner'"/> 		<!-- 轮播广告 -->
			 	<modlueTuijian :module="moduleObject[element]" :moduleKey="element" v-if="element == 'articles'"/>	<!-- 文章列表 -->
				<moduleArticle :module="moduleObject[element]" :moduleKey="element" v-if="element == 'text'"/>		<!-- 富文本 -->
				<moduleButton :module="moduleObject[element]" :moduleKey="element" v-if="element == 'button'" />	<!-- 跳转按钮 -->
			</div>
			<div class="placeholder" :data-index="index + 1" v-if="placeholder">放在这里</div>
		</section>
				
	</div>
</template>

<script>
import moduleNav from '../module/nav.vue';
import moduleDuo from '../module/duo.vue';
import conTopimg from '../module/contopimg.vue';
import moduleArticle from '../module/article.vue';
import moduleButton from '../module/button.vue';
import modlueTuijian from '../module/tuijian.vue';

export default {
	name: 'Module',
	props: {
	 	'moduleList':{type:Array},
	 	'moduleKeyList':{type:Array},
		'moduleObject':{type:Object},
		'prev':{default:false}
	},
	components: { moduleNav, moduleDuo, modlueTuijian,conTopimg,moduleButton,moduleArticle},
	data() {
		return {
			placeholder: false,
			selectIndex: '', //选中
			activeIndex: '' //当前活动active
		};
	},

	methods: {
		//模块点击
		moduleClick(index) {
			this.selectIndex = index;
			this.$emit('moduleClick', index);
		},

		//鼠标移入模块
		mouseovers(index) {
			let top = event.target.getBoundingClientRect().top;
			this.$emit('moduleMouseovers', { top: top, index: index });
		},

		//鼠标离开模块
		mouseout() {
			this.$emit('moduleMouseout');
		}
	}
};
</script>
