<template>	
	<div class="module-item-nav module-common">
		<div class="item-nav-con col5">
			<div class="item" v-for="(item,index) in module" :key="index" @click="$util.openAttr(module,index)">
				<img class="item-nav-img" v-if="item.toolsImg.length > 0" :src="item.toolsImg.length > 0 ? item.toolsImg : ''" >
				<img v-else src="../assets/none_img.png">
				<div class="item-nav-text">
					<moduleText :item="item" :moduleKey="moduleKey"/>
				</div>
			</div>
		</div>					
	</div>
</template>

<script>
import moduleText from './text.vue'
import moduleBg from './bg.vue'
export default{
	props:['module','moduleKey'],
	components:{moduleText,moduleBg},
	data(){
		return{
			nav:[],
		}
	},
	created(){},
	
}
</script>

<style>
</style>