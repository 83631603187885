<template>
	 <div class="xcx-page-topimg" >	 
		<div class="note" >
			<p>{{module}}</p>
		</div>
		<div class="module-background"></div>
	 </div>
</template>

<script>	
export default{
	name:'conTopimg',
	props:{
		'module':{type:String},
	},
	created(){},
	data(){
		return{}
	},
}
</script>

<style>
</style>
