<template>
	<div>
		<div class="item c">
			<div class="tit">添加图片：</div>
			<div class="input flex">
				<div class="module-upload c" v-if="item.imgUrl.length > 0">
					<img :src="item.imgUrl" class="avatar" />
					<i class="el-icon-close" @click="deleFile"></i>
				</div>
				<el-upload  v-else class="swiper-upload"  action="api/web/tool/file/upload" :headers="headers" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
					<div style="width:150px;height:100px;display:flex;align-items:center;justify-content:center;">
						<i style="font-size:40px;color:#d1d1d1" class="el-icon-camera-solid"></i>
					</div>
				</el-upload>
				<div class="tips">点击更换图片<br/>建议宽度750px</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AttrImg',
	props: ['item'],
	data() {
		return {
			headers: {
				Authorization: this.$store.state.user.Token
			},
		};
	},
	methods: {
		selectImage() {
			document.getElementById('fileupload').click();
		},

		async upload(e) {
			var that = this;
			let file = e.target.files[0];
			let arr = [];
			arr.push({ name: file.name, size: file.size, path: file });
			let loading = this.$loading({ text: '上传中' });
			this.$util.uploadImg(arr, function(res) {
				that.item.img = res;
				loading.close();
			});
		},

		//图片上传
		handleAvatarSuccess(res, file) {
			if(res.code == 200){
				this.item.imgUrl = res.data.path;
			}else{
				this.$message.error(res.msg);
			}
		},
		//图片验证
		beforeAvatarUpload(file) {
			const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
			// const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isJPG) {
				this.$message.error('Banner图片只能是 JPG 或 PNG 格式!');
			}
			// if (!isLt2M) {
			// 	this.$message.error('上传头像图片大小不能超过 2MB!');
			// }
			// return isJPG && isLt2M;
			return isJPG
		},

		//删除文件
		async deleFile() {			
			this.item.imgUrl = '';
		}
	}
};
</script>
<style scoped>
.swiper-upload{
	width: 150px !important;
	height: 100px !important;
	background-color: #f8f8f8;
	border: 1px solid #f1f1f1;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}
</style>