<template>
	<div class="page" @click="closeAttr" style="position:relative;display:flex;justify-content:space-between;overflow:hidden;">
		<!--左侧模块菜单栏目-->
		<Left ref="left" @componentsClick="componentsClick" @placeholder="placeholderFun" />

		<!-- 中间展示部分 -->
		<div class="xcx-module-container" >		
			<!-- 顶部标题 -->
			<conTitle  name="首页"/>	
			
			<div class="xcx-module" ref="editorWrapper" @dragover.prevent @dragenter="dragenter" @dragleave="dragleave" @dragover="dragovers" @drop="drops">
				<div class="placeholder" :data-index="0" v-show="placeholder">放在这里</div>
				<!--DIY组件部份-->
				<Module :moduleList="moduleList" :moduleKeyList="moduleKeyList" :moduleObject="moduleObject" ref="module" @moduleClick="moduleClick" @moduleMouseovers="moduleMouseovers" @moduleMouseout="moduleMouseout" />
				<div class="xcx-nomodule" v-if="moduleList.length == 0 && !placeholder"><h2>拖动或点击左侧模块进行页面自定义</h2></div>
			</div>

			<!--操作-->
			<ul class="xcx-module-option" :style="'top:' + optionTop + 'px'" @mouseenter="showOption = true" @mouseleave="showOption = false" v-if="showOption && moduleList.length > 0">
				<li v-if="moduleList.length != 1 && activeIndex != 0" @click="up"><el-tooltip effect="dark" content="上移" placement="right"><span class="el-icon-top"></span></el-tooltip></li>
				<li @click="del_module(activeIndex)"><el-tooltip effect="dark" content="删除" placement="right"><span class="el-icon-close"></span></el-tooltip></li>
				<li v-if="moduleList.length != 1 && activeIndex != moduleList.length - 1" @click="down"><el-tooltip effect="dark" content="下移" placement="right"><span class="el-icon-bottom"></span></el-tooltip></li>
			</ul>
		</div>

		<!--模块属性-->
		<div style="width:400px;">
			<transition name="right"><Attr v-if="showAttr" :attr="curAttrData" :moduleKey="moduleKey"/></transition>
		</div>
		<!-- 底部全局保存按钮 -->
		<el-button class="diy_save_button" type="primary" round @click="save">保存自定义</el-button>
		
	</div>
</template>

<script>
import Left from './components/Left.vue';
import Module from './components/Module.vue';
import Attr from './components/Attr.vue';
import conAttr from './components/conAttr.vue';
import conTitle from './module/contitle.vue';
import conTopimg from './module/contopimg.vue'; 
import './assets/css/diy.scss';
import {
	indexDetail,indexDetailUpdate
} from "@/api/platform/index";
export default {
	name: "indexDiy",
	components: {Left, Module, Attr, conAttr, conTitle, conTopimg },
	
	data() {
		return {
			id: '', //页面ID
			activeIndex: '',    //移上去模块
			selectIndex: '',    //选中模块
			placeholder: false, //显示空白区域
			showOption: false,  //显示操作按钮
			optionTop: 0,   //操作按钮距离顶部
			moduleList: [], //组件列表
			showAttr: false,   //显示模块属性
			curAttrData: [],   //当前属性数据
			item:{},
			moduleObject:{},
			moduleKeyList:[],
			moduleKey:'',
			id:'',
			module:{}
		};
	},	

	mounted() {
		this.id = this.$route.params.parentId;
		this.initPage();
	},
	
	methods: {

		//初始化页面
		initPage(){
			let that = this;
			let moduleKeyList = [];
			let moduleObject = {};
			indexDetail(that.id).then(res => {
				if(res.code == 200){
					that.module = res.data;
					if(that.module.pluginOrders != null ){
						for(var i = 0; i < that.module.pluginOrders.length; i++){
							moduleKeyList.push(that.module.pluginOrders[i]);
							moduleObject[that.module.pluginOrders[i]] = that.module[that.module.pluginOrders[i]];
						}
					}
					that.moduleList = moduleKeyList;
					that.moduleKeyList = moduleKeyList;
					that.moduleObject = moduleObject;
				}else{
					that.$message.error(res.msg);
				}
			})
			
		},
		
		//左侧点击添加组件
		componentsClick(json) {
			let that = this;
			let item = JSON.parse(json);
			if(that.moduleKeyList.length > 0){
				// 如果数组里面本身不存在这个对象则把这个加进去
				let key = item.moduleKey;
				var hasKey = false;
				for(var i of that.moduleKeyList){
					if(i == key){
						hasKey = true;
					}
				}
				if(hasKey){
					that.$message.warning('页面已包含该组件');
				}else{
					that.moduleKeyList.push(item.moduleKey);
					if(item.moduleKey == 'name'){
						that.moduleObject['name'] = item.name;
					}else if(item.moduleKey == 'tools'){
						that.moduleObject['tools'] = item.tools;
					}else if(item.moduleKey == ['banner']){
						that.moduleObject['banner'] = item.banner;
					}else if(item.moduleKey == ['articles']){
						that.moduleObject['articles'] = item.articles;
					}else if(item.moduleKey == ['button']){
						that.moduleObject['button'] = item.button;
					}else if(item.moduleKey == ['text']){
						that.moduleObject['text'] = item.text;
					}
					that.moduleObject = JSON.parse(JSON.stringify(that.moduleObject));
				}
			}else{
				that.moduleKeyList.push(item.moduleKey);
				if(item.moduleKey == 'name'){
					that.moduleObject['name'] = item.name;
				}else if(item.moduleKey == 'tools'){
					that.moduleObject['tools'] = item.tools;
				}else if(item.moduleKey == ['banner']){
					that.moduleObject['banner'] = item.banner;
				}else if(item.moduleKey == ['articles']){
					that.moduleObject['articles'] = item.articles;
				}else if(item.moduleKey == ['button']){
					that.moduleObject['button'] = item.button;
				}else if(item.moduleKey == ['text']){
					that.moduleObject['text'] = item.text;
				}
				that.moduleObject = JSON.parse(JSON.stringify(that.moduleObject));
			}

			that.$nextTick(() => {
				if (that.$refs.editorWrapper) {
					that.$util.scrollTop(that.$refs.editorWrapper, that.$refs.editorWrapper.scrollHeight, 500);
				}
			});
		},

		//空白区域
		placeholderFun(flag,item) {
			let that = this;
			this.placeholder = flag;
			this.$refs.module.placeholder = flag;
			that.item = item;
		},

		//当拖曳元素进入目标元素的时候触发的事件，此事件作用在目标元素上
		dragenter(event) {
			// 拖动到容器的“放到这里（它是个提示）”盒子，就给盒子加上焦点样式
			if (event.target.classList.contains('placeholder')) {
				event.target.classList.add('on');
			}
		},

		//当拖动的元素或文本选择离开有效的放置目标时将触发该事件。此事件作用在目标元素上
		dragleave(event) {
			// 离开目标容器的“放到这里（它是个提示）”盒子，就给盒子移除焦点样式
			if (event.target.classList.contains('placeholder')) {
				event.target.classList.remove('on');
			}
		},

		// 拖拽元素在目标元素上移动的时候触发的事件
		dragovers(event) {
			event.preventDefault();
		},

		// 拖拽组件区域元素的事件drop 被拖拽的元素在目标元素上同时鼠标放开触发的事件
		drops(event) {
			console.log(event);

			let that = this;
			
			this.placeholder = false;
			if (event.target.classList.contains('placeholder')) {
				event.target.classList.remove('on');
			}
			event.dataTransfer.clearData(); // 清除dragstart事件传递的参数

			if(that.moduleKeyList.length > 0){
				let item = that.item;
				// 如果数组里面本身不存在这个对象则把这个加进去
				let key = item.moduleKey;
				var hasKey = false;
				for(var i of that.moduleKeyList){
					if(i == key){
						hasKey = true;
					}
				}
				if(hasKey){
					that.$message.warning('页面已包含该组件');
				}else{
					// that.moduleKeyList.push(item.moduleKey);
					let index = event.target.getAttribute('data-index');
					that.moduleKeyList.splice(index, 0, item.moduleKey);
					if(item.moduleKey == 'name'){
						that.moduleObject['name'] = item.name;
					}else if(item.moduleKey == 'tools'){
						that.moduleObject['tools'] = item.tools;
					}else if(item.moduleKey == ['banner']){
						that.moduleObject['banner'] = item.banner;
					}else if(item.moduleKey == ['articles']){
						that.moduleObject['articles'] = item.articles;
					}else if(item.moduleKey == ['button']){
						that.moduleObject['button'] = item.button;
					}else if(item.moduleKey == ['text']){
						that.moduleObject['text'] = item.text;
					}
					that.moduleObject = JSON.parse(JSON.stringify(that.moduleObject));
				}
			}else{
				let item = that.item;
				// that.moduleKeyList.push(item.moduleKey);
				let index = event.target.getAttribute('data-index');
				that.moduleKeyList.splice(index, 0, item.moduleKey);
				if(item.moduleKey == 'name'){
					that.moduleObject['name'] = item.name;
				}else if(item.moduleKey == 'tools'){
					that.moduleObject['tools'] = item.tools;
				}else if(item.moduleKey == ['banner']){
					that.moduleObject['banner'] = item.banner;
				}else if(item.moduleKey == ['articles']){
					that.moduleObject['articles'] = item.articles;
				}else if(item.moduleKey == ['button']){
					that.moduleObject['button'] = item.button;
				}else if(item.moduleKey == ['text']){
					that.moduleObject['text'] = item.text;
				}
				that.moduleObject = JSON.parse(JSON.stringify(that.moduleObject));
			}
			
		},

		//模块获取焦点，显示操作按钮
		moduleMouseovers(item) {
			this.optionTop = item.top - 130;
			this.showOption = true;
			this.activeIndex = item.index;
		},

		// 容器失去焦点，隐藏按钮区域
		moduleMouseout() {
			this.showOption = false;
		},

		//上移
		up() {
			this.moduleKeyList.splice(this.activeIndex - 1, 0, this.moduleKeyList[this.activeIndex]);
			this.moduleKeyList.splice(this.activeIndex + 1, 1);
			// this.moduleList.splice(this.activeIndex - 1, 0, this.moduleList[this.activeIndex]);
			// this.moduleList.splice(this.activeIndex + 1, 1);
			this.selectIndex = this.activeIndex - 1;
			this.showOption = false;
		},

		//下移
		down() {
			// this.moduleList.splice(this.activeIndex + 2, 0, this.moduleList[this.activeIndex]);
			// this.moduleList.splice(this.activeIndex, 1);

			this.moduleKeyList.splice(this.activeIndex + 2,0,this.moduleKeyList[this.activeIndex]);
			this.moduleKeyList.splice(this.activeIndex,1);
			this.showOption = false;
		},
		
		//组件点击
		moduleClick(index) {
			let that = this;
			if(that.moduleKeyList[index] == 'name'){
				return;
			}else{
				that.selectIndex = index;
				that.showAttr = true;
				that.curAttrData = that.moduleObject[that.moduleKeyList[this.selectIndex]];
				that.moduleKey = that.moduleKeyList[this.selectIndex];
			}
		},
		
		//关闭属性
		closeAttr() {
			this.showAttr = false;
		},
		
		
		// 删除模块
		del_module(index){
			let that = this;
			console.log('删除模块');
			console.log(index);
			that.$confirm('此操作将删除该模块, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
			}).then(() => {
				var key = that.moduleKeyList[index].toString();
				// that.moduleList.splice(index, 1);
				that.moduleKeyList.splice(index,1);
				delete that.moduleObject[key];
				that.moduleObject = JSON.parse(JSON.stringify(that.moduleObject));
				that.$message({
					type: 'success',
					message: '删除成功!'
				});
			}).catch(() => {
		        
			});
		},

		//保存
		save() {
			let that = this;
			that.moduleObject['pluginOrders'] = that.moduleKeyList;
			that.moduleObject['homepageId'] = that.id;
			console.log(that.moduleObject);
			this.$confirm('确定保存该自定义页面？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let hasBanner = that.moduleObject['pluginOrders'].includes('banner');			//	判断是否含有轮播广告模块
				if(hasBanner){
					for(var i of that.moduleObject['pluginOrders']){							//	自定义首页各模块校验
						if(i == 'name'){

						}else if(i == 'banner'){														//  轮播图板块校验
							if(that.moduleObject[i].bannerSecond == '' || Number(that.moduleObject[i].bannerSecond < 0)){
								that.$message.error('保存失败！轮播广告间隔时间不能为空且不能小于0');
								return;
							}else if(that.moduleObject[i].bannerHeight == '' || Number(that.moduleObject[i].bannerHeight <= 0)){
								that.$message.error('保存失败！轮播广告行高不能为空且必须大于0');
								return;
							}else if(that.moduleObject[i].jumpType == 3){
								for(var j in that.moduleObject[i].banners){
									let banner = that.moduleObject[i].banners[j];
									if(banner.imgUrl == ''){
										that.$message.error('保存失败！轮播广告模块【轮播广告'+ (Number(j) + 1) +'】的轮播图不能为空');
										return;
									}else if(banner.appId == '' && banner.jumpUrl == ''){
										that.$message.error('保存失败！轮播广告模块【轮播图片'+ (Number(j) + 1) +'】外链地址、appId必须填入一项');
										return;
									}else if(banner.appId.length > 0 && banner.jumpUrl.length > 0){
										that.$message.error('保存失败！轮播广告模块【轮播图片'+ (Number(j) + 1) +'】外链地址、appId只能填入一项');
										return;
									}
								}
							}
						}else if(i == 'tools'){													//  工具栏板块校验
							for(var j in that.moduleObject[i]){
								let tool = that.moduleObject[i][j];
								if(tool.toolsImg == ''){
									that.$message.error('保存失败！工具栏模块【工具'+ (Number(j) + 1) +'】的展示图片不能为空');
									return;
								}else if(tool.toolsName == ''){
									that.$message.error('保存失败！工具栏模块【工具'+ (Number(j) + 1) +'】的工具名称不能为空');
									return;
								}else if(tool.appId == '' && tool.jumpUrl == ''){
									that.$message.error('保存失败！工具栏模块【工具'+ (Number(j) + 1) +'】的外链地址、appId必须填入一项');
									return;
								}else if(tool.appId.length > 0 && tool.jumpUrl.length > 0){
									that.$message.error('保存失败！工具栏模块【工具'+ (Number(j) + 1) +'】的外链地址、appId只能填入一项');
									return;
								}else if(tool.appId.length > 0 && tool.indexUrl == ''){
									that.$message.error('保存失败！工具栏模块【工具'+ (Number(j) + 1) +'】的小程序首页地址不能为空');
									return;
								}
							}
						}else if(i == 'articles'){												// 文章列表模块校验
							for(var j in that.moduleObject[i]){
								let article = that.moduleObject[i][j];
								if(article.articleTitle == ''){
									that.$message.error('保存失败！文章列表模块【文章'+ (Number(j) + 1) +'】的文章标题不能为空');
									return;
								}else if(article.articleType == 2){
									if(article.articleInfo == ''){
										that.$message.error('保存失败！文章列表模块【文章'+ (Number(j) + 1) +'】站内编辑内容不能为空');
										return;
									}
								}else if(article.articleType == 1){
									if(article.appId == '' && article.jumpUrl == ''){
										that.$message.error('保存失败！工具栏模块【工具'+ (Number(j) + 1) +'】的外链地址、appId必须填入一项');
										return;
									}else if(article.appId.length > 0 && article.jumpUrl.length > 0){
										that.$message.error('保存失败！工具栏模块【工具'+ (Number(j) + 1) +'】的外链地址、appId只能填入一项');
										return;
									}else if(article.appId.length > 0 && article.indexUrl == ''){
										that.$message.error('保存失败！工具栏模块【工具'+ (Number(j) + 1) +'】的小程序首页地址不能为空');
										return;
									}
								}
							}
						}else if(i == 'text'){													//	富文本模块校验
							if(that.moduleObject[i].textInfo == ''){
								that.$message.error('保存失败！富文本模块文本内容不能为空');
								return;
							}
						}else if(i = 'button'){													//	按钮模块校验
							if(that.moduleObject[i].buttonName == ''){
								that.$message.error('保存失败！跳转按钮模块按钮名称不能为空');
								return;
							}else if(that.moduleObject[i].jumpUrl == '' && that.moduleObject[i].appId == ''){
								that.$message.error('保存失败！跳转按钮模块的外链地址、appId必须填入一项');
								return;
							}else if(that.moduleObject[i].jumpUrl > 0 && that.moduleObject[i].appId  > 0){
								that.$message.error('保存失败！跳转按钮模块的外链地址、appId只能填入一项');
								return;
							}else if(that.moduleObject[i].appId.length > 0 && that.moduleObject[i].indexUrl == ''){
								that.$message.error('保存失败！跳转按钮模块的小程序首页地址不能为空');
								return;
							}
						}
					}
				}else{
					that.$message.error('自定义首页必须包含轮播广告模块');
					return;
				}
				indexDetailUpdate(that.moduleObject).then(res => {
					if (res.code == 200) {
						that.$message.success('保存成功');
						setTimeout(function(){
							that.$router.go(-1);
						},1200)
					} else {
						that.$message.error(res.msg);
					}
				}).catch(e => {
					that.$message.error(e.msg);
				});
			}).catch(_ => {
			});
		},
	}
};
</script>
<style scoped="scoped" lang="scss">
@import 'assets/css/diy.scss';
.page {
	height: 100%;
	background: #f1f1f1;
}
.diy_save_button{
	position: fixed;
	left: 50%;
	bottom: 20px;
	width: 200px;
	height: 40px;
	line-height: 40px;
	color: white;
	text-align: center;
	background-color: #46A1FF;
	font-size: 14px;
	margin-left: -20px;
	z-index: 9;
	padding: 0;
}
</style>