import { render, staticRenderFns } from "./Left.vue?vue&type=template&id=2fc0a13f&scoped=true&"
import script from "./Left.vue?vue&type=script&lang=js&"
export * from "./Left.vue?vue&type=script&lang=js&"
import style0 from "./Left.vue?vue&type=style&index=0&id=2fc0a13f&prod&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc0a13f",
  null
  
)

export default component.exports