<template>
	<div>
		<div class="item">
			<div class="tit">展示图片：</div>
			<div class="input">
				<template >
					<div class="input-line">
						<div class="module-upload" v-if="icon.toolsImg.length > 0">
							<img :src="icon.toolsImg" class="avatar" />
							<i class="el-icon-close" @click="icon.toolsImg = ''"></i>
						</div>
						<el-upload  v-else class="module-upload" action="api/web/tool/file/upload" :headers="headers" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
							<i class="el-icon-camera-solid"></i>
						</el-upload>
						<div class="tips">
							点击上传图片
							<br />
							尺寸建议：40*40
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AttrIcon',
	props: ['icon'],
	data() {
		return {
			headers: {
				Authorization: this.$store.state.user.Token
			},
		};
	},
	methods: {
		selectImage() {
			document.getElementById('fileupload').click();
		},

		async upload(e) {
			var that = this;
			let file = e.target.files[0];
			let arr = [];
			arr.push({ name: file.name, size: file.size, path: file });
			let loading = this.$loading({ text: '上传中' });
			this.$util.uploadImg(arr, function(res) {
				that.icon.z = res;
				loading.close();
			});
		},

		//图片上传
		handleAvatarSuccess(res, file) {
			if(res.code == 200){
				this.icon.toolsImg = res.data.path;
			}else{
				this.$message.error(res.msg);
			}
		},
		//图片验证
		beforeAvatarUpload(file) {
			const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
			// const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isJPG) {
				this.$message.error('Banner图片只能是 JPG 或 PNG 格式!');
			}
			// if (!isLt2M) {
			// 	this.$message.error('上传头像图片大小不能超过 2MB!');
			// }
			// return isJPG && isLt2M;
			return isJPG
		},

		//删除文件
		async deleFile() {
			const json = await this.$api.deleteFile({ m: 'get', q: { fileUrl: this.icon.z } });
			this.icon.z = '';
		}
	}
};
</script>
<style scoped>
.avatar-uploader{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 0;
}
</style>