<template>
	<div class="toptitle">
		<ul class="module-attr-tab">
			<li class="">组件设置</li>
			<span class="">企业会员名称</span>
		</ul>
		<!--基础设置-->
		<template>
			<div class="att-con-box">
				<div class="attr-nav-item">
					<div class="att-con-pd">
						<AttrText :text="attr.note" />
					</div>
				</div>
			</div>
		</template>
		<el-button class="con_fix_btn" type="primary" round @click="linkInput">保存</el-button>
	</div>
</template>

<script>
import AttrImg from './img.vue';	
import AttrText from './text.vue';
import AttrLink from './link.vue';
import AttrBase from './base.vue';

export default {
	name: 'AttrConTopimg',
	props: ['attr'],
	components: {AttrImg, AttrText, AttrLink, AttrBase },
	data() {
		return {};
	},

	created(){},

	methods: {
		// 确定按钮获取页面数据
		linkInput(){
			let that = this;
			if(that.$props.attr.note.t.length > 0){
				that.$message.success(that.$props.attr.note.t);
			}else{
				that.$message.error('名称不能为空');
			}
		}
	}
};
</script>

<style>
.con_fix_btn{
	position: absolute;
	bottom: 20px;
	left: 50%;
	width: 200px;
	margin-left: -80px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	color: white;
	border-radius: 50px;
	text-align: center;
	cursor: pointer;
	border: none;
	padding: 0 !important;
}
.toptitle{
	position: relative;
	height: 100%;
}
</style>