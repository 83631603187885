<template>	
	<div class="module-item-nav module-common" >
		<!--样式一-->
		<div class="article_box">
			<div class="item" >
				<p class="editorTxt" v-html="change(module.textInfo)" v-if="module.textInfo.length > 0"></p>
				<p v-else style="font-size:14px;color:#333;">点击编辑富文本</p>
			</div>
		</div>
	</div>
</template>

<script>
export default{
	props:['module'],
	data(){
		return{}
	},
	created(){
	},
	methods:{
		change:function(textInfo){
			textInfo = textInfo.replace(/;/g,";</br>");
			textInfo = textInfo.replace(/；/g,"；</br>");
			return textInfo;
		}	
	}
}
</script>

<style>
.article_box{
	box-sizing: border-box;
	padding: 10px 5px;
	background-color: white;
}
.link_button{
	display: block;
	width: 300px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	color: white;
	border-radius: 50px;
	text-align: center;
	cursor: pointer;
	border: none;
	padding: 0 !important;
	margin: 0 auto;
}
.editorTxt{
	white-space:pre-wrap; 
	word-wrap: break-word;
	table-layout: fixed;
	word-break: break-all;
}
</style>