<template>
	<div>
		<ul class="module-attr-tab">
			<li>组件设置</li>
			<span>跳转按钮</span>
		</ul>
		<!--基础设置-->
		<div class="att-con-box">
			<div class="attr-nav-item">
				<transition name="down">
					<div class="hm">
						<div class="item c">
							<div class="tit">按钮名称：</div>
							<div class="input flex">
								<input type="text" placeholder="请输入按钮名称" maxlength="100" v-model="attr.buttonName" class="intxt" />
							</div>
						</div>
						<AttrLink :link="attr" ref="link"/>
					</div>
				</transition>
			</div>
		</div>
		<el-button class="link_fix_btn" type="primary" round @click="navSave">保存</el-button>
	</div>
</template>

<script>
import AttrText from './text.vue';
import AttrIcon from './icon.vue';
import AttrLink from './link.vue';

export default {
	name: 'AttrButton',
	props: ['attr'],
	components: { AttrText, AttrIcon, AttrLink},
	data() {
		return {
		};
	},

	created(){
	},

	methods: {
		// 确定按钮获取页面数据
		navSave(){
			let that = this;
			if(that.attr.buttonName.length == ''){
				that.$message.warning('跳转按钮模块按钮名称不能为空');
				return;
			}else{
				if(that.$refs.link.linkType == true){
					if(that.attr.jumpUrl == ''){
						that.$message.warning('跳转按钮模块设置跳转外链类型的外链地址不能为空');
						return;
					}else{
						that.attr.appId = '';
						that.attr.indexUrl = '';
					}
				}else{
					if(that.attr.appId == ''){
						that.$message.warning('跳转按钮模块设置跳转小程序类型的APPID不能为空');
						return;
					}else if(that.attr.indexUrl == ''){
						that.$message.warning('跳转按钮模块设置跳转小程序类型的首页地址不能为空');
						return;
					}else{
						that.attr.jumpUrl = '';
					}
				}
			}
			that.$message.success('该模块已提交');
		},
	}
};
</script>

<style>
.att-con-box{
	box-sizing: border-box;
	padding-bottom: 100px;
}
.nav_add_btn{
	background-color: #46A1FF;
	color: white;
	box-sizing: border-box;
	padding: 5px 10px;
	font-size: 12px;
	text-align: center;
	border-radius: 4px;
	margin-right: 5px;
}
.link_fix_btn{
	position: fixed;
	bottom: 20px;
	z-index: 3;
	right: 100px;
	width: 200px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	color: white;
	border-radius: 50px;
	text-align: center;
	cursor: pointer;
	border: none;
	padding: 0 !important;
}
</style>