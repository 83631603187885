<template>
	<div class="left">
		<!--模块-->
		<div class="leftcon">
			<!--页面-->
			<div class="left-control">
				<div class="left_module" v-for="(item, index) in list" :key="index">
					<div class="left_item"  @click="comClick(item)" @dragend="dragend(item)" @dragstart="dragstart(item)" draggable="true">
						<img draggable="false" :src="$util.icon(item.moduleImg)" />
						<span>{{item.conName}}</span>
					</div>
				</div>
			</div>
		</div>		
	</div>
</template>
<script>
import module from '../util/module.js';
export default {
	name: 'Left',
	data() {
		return {
			list: [], //模块
		};
	},

	created(){},

	mounted() {		
		// 初始化左侧组件
		this.list = module.pageData;
	},

	methods: {
		
		dragstart(item) {
			event.dataTransfer.setData('text', JSON.stringify(item));
			event.dataTransfer.effectAllowed = 'copyMove';
			this.$emit('placeholder', true, item);
		},
		
		dragend(item) {
			this.$emit('placeholder', false, item);
		},
		
		comClick(item) {
			let json = JSON.stringify(item);
			this.$emit('componentsClick', json);
		},
		
	}
};
</script>
<style scoped="scoped" lang="scss">
$color: #66B1FF;
.left {
	width: 300px;
	display: flex;
	background: white;
	overflow: hidden;
	left: 0;
	top: 0;
	height: 100%;
	.ul1 {
		width: 80px;
		li {
			color: #8e9499;
			cursor: pointer;
			height: 100px;
			&.on {
				background: #1a242b;
				color: #fff;
			}
			&:hover {
				color: #fff;
			}
			i {
				font-size: 18px;
				padding-bottom: 5px;
			}
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			font-weight: bold;
			font-size: 14px;
		}
	}
	.leftcon{ flex:1; height:100%; background:#fff; overflow:hidden auto;border-left: 1px solid #eee;}
	
	.left_item {
		&:hover {
			border-color: $color;
			box-shadow: #409eff 0 0 10px;
		}
		img {
			width: 80%;
			margin: 10px 0;
		}
		span{
			border-top: 1px solid #f1f1f1;
			flex: 1;
			width: 100%;
			line-height: 30px;
			font-size: 12px;
		}
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: move;
		text-align: center;
		width: 90%;
		border: 1px solid #f1f1f1;
		margin: 10px auto;
	}
	.left-control{	
		ul{border-top:1px solid #eee; padding:0 20px;}
		ul li{
			height:50px; border:1px solid #eee; border-radius:5px; margin-top:12px; display: flex; background:#f7f7f7; padding:0 12px; justify-content:space-between; align-items:center; font-size:14px; color:#666;
			.fl{flex:1; cursor:pointer; height:50px; line-height:50px; &.on{ color:$color;} }
			.fr{
				  button{background:none; border:0; -webkit-appearance:none; &[disabled]:hover{ cursor:not-allowed; em{ color:#999;cursor:not-allowed; } } }
					em{ margin-left:8px; font-size:18px; color:#999; cursor:pointer; &:hover{ color:$color;} }
			}		
		}
		.add-page{ span{margin-right:12px;} cursor:pointer; height:45px; border:1px solid #eee; margin:20px; font-size:14px; display: flex; align-items:center; justify-content:center; background:$color; color:#fff; border-radius:40px;}
	}
	.left-module{
		display: block;	
		width: 100%;
	}
	::-webkit-scrollbar {
    /*滚动条整体样式*/
    width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
	}
	::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 10px;
		box-shadow   : #A8A8A8;
		background   : #535353;
	}
	::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		box-shadow   : #A8A8A8;
		border-radius: 10px;
		background   : #ededed;
	}
}
</style>