/**
 * 工具栏组件
 * [listShow] 列表展开关闭 [false隐藏 true展开]
 * [appId] 小程序跳转列表 
 * [indexUrl] 页面路由
 * [jumpUrl] 外链跳转链接
 * [toolsName] 工具标题标题
 * [toolsImg] 工具icon图标
 */

const toolItem = {appId: "",indexUrl: "",jumpUrl: "",listShow:false,toolsImg: "",toolsName: "工具标题"};

/**
 * 轮播广告组件
 * [listShow] 列表展开关闭 [false隐藏 true展开]
 * [appId] 小程序跳转列表 
 * [indexUrl] 页面路由
 * [jumpUrl] 外链跳转链接
 * [bannerTitle] 背景图标题
 * [imgUrl] 工具icon图标
 */
const bannerItem = {appId: "",indexUrl: "",jumpUrl: "",listShow:false,bannerTitle: "",imgUrl: ""};

/**
 * 文章列表组件
 * [listShow] 列表展开关闭 [false隐藏 true展开]
 * [appId] 小程序跳转列表 
 * [indexUrl] 页面路由
 * [jumpUrl] 外链跳转链接
 * [articleTitle] 文章标题
 * [articleType]  文章跳转类型
 * [articleInfo] 文章富文本
 */
const articleItem = {appId: "",indexUrl: "",jumpUrl: "",listShow:false,articleInfo:'',articleTitle:'',articleType:1,};



//页面数据 【type:1 导航模块 2 多功能模块 3 图文模块 4 商品模块 5 推荐模块】
const pageData = [
    /**
     * 企业会员名称
     * conName 导航名称 ｜ moduleKey 导航标识 ｜ moduleImg 导航模块图 |  (name 模块内容部分)
     */
    {
        conName: '企业会员名称',
        moduleKey:'name',
        moduleImg:'s1_1.png',
        name:'企业会员名称',
    },

    /**
     * 工具栏
     * conName 导航名称 ｜ moduleKey 导航标识 ｜ moduleImg 导航模块图 |  (tools 模块内容部分)
     */
    {
        conName: '工具栏',
        moduleImg:'s2_1.png',
        moduleKey:'tools',
        tools: [toolItem, toolItem, toolItem, toolItem, toolItem],
    },

    /**
     * 轮播广告
     * conName 导航名称 ｜ moduleKey 导航标识 ｜ moduleImg 导航模块图 |  (banner 模块内容部分)
     */
    {
        conName: '轮播广告',
        moduleImg:'s3_1.png',
        moduleKey:'banner',
        banner: {bannerHeight:0,bannerSecond:0,jumpType:3,banners:[bannerItem,bannerItem]},
    },

    /**
     * 文章列表
     * conName 导航名称 ｜ moduleKey 导航标识 ｜ moduleImg 导航模块图 |  (articles 模块内容部分)
     */
    {
        conName: '文章列表',
        moduleImg:'s4_1.png',
        moduleKey:'articles',
        articles: [articleItem,articleItem],
    },

    /**
     * 富文本
     * conName 导航名称 ｜ moduleKey 导航标识 ｜ moduleImg 导航模块图 |  (text 模块内容部分)
     */
    {
        conName: '富文本',
        moduleImg:'s5_1.png',
        moduleKey:'text',
        text:{textInfo:''}
    },

    /**
     * 跳转按钮
     * conName 导航名称 ｜ moduleKey 导航标识 ｜ moduleImg 导航模块图 |  (button 模块内容部分)
     */
    {
        conName: '跳转按钮',
        moduleImg:'s6_1.png',
        moduleKey:'button',
        button:{buttonName: '',appId:'',indexUrl:'',jumpUrl:''}
    },
]

export default {
    moduleNav: toolItem,
    moduleArc: articleItem,
    moduleDuo: bannerItem,
    pageData: pageData,
}