<template>
	 <div>
		<div class="item">
			<div class="tit">跳转类型：</div>
			<div class="input">
				<div class="input-line"> 
					<div class="sp1" @click="linkType=false">
						<div class="sp_img" :class="linkType == false ? 'on' : ''"><img src="../assets/gou.png" alt=""></div>
						<span class="sp_tip">跳转小程序</span>
					</div>
					<div class="sp1" @click="linkType=true">
						<div class="sp_img" :class="linkType == true ? 'on' : ''"><img src="../assets/gou.png" alt=""></div>
						<span class="sp_tip">跳转外链</span>
					</div>
				</div>
			</div>
		</div>
		<div class="input-line" v-if="linkType">
			<div class="item c"> 
				<div class="tit">外链地址：</div>
				<div class="input flex">
					<input type="text" placeholder="" maxlength="100" v-model="link.jumpUrl" class="intxt" />
				</div>
			</div>
		</div>
		<div class="input-line" v-else>
			<div class="item c">
				<div class="tit" style="padding-left:11px;">APPID：</div>
				<div class="input flex">
					<input type="text" placeholder="" maxlength="100" v-model="link.appId" class="intxt" />
				</div>
			</div>
			<div class="item c">
				<div class="tit">首页地址：</div>
				<div class="input flex">
					<input type="text" placeholder="" maxlength="100" v-model="link.indexUrl" class="intxt" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AttrText from './text.vue';
export default{
	name:'AttrLink',
	props:['link'],
	components:{AttrText},
	data(){
		return{
			linkType:true
		}
	},
	mounted(){
		if(this.link.appId != null && this.link.appId.length > 0){
			this.linkType = false;
		}
	},
	methods:{								
		
	}
}	
</script>