<template>	
	<div class="module-item-nav module-common" >
		<!--样式一-->
		<div class="link_button_box">
			<div class="item" >
				<div class="link_button">{{module.buttonName.length > 0 ? module.buttonName : '按钮名称'}}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default{
	props:['module'],
	data(){
		return{
			nav:[],
		}
	},
	created(){
	},
	computed:{
			
	}
}
</script>

<style>
.link_button_box{
	box-sizing: border-box;
	padding: 10px 0;
}
.link_button{
	display: block;
	width: 300px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	color: white;
	border-radius: 10px !important;
	text-align: center;
	cursor: pointer;
	border: none;
	padding: 0 !important;
	margin: 0 auto;
	background-color: red;
}
</style>