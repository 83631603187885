<template>
	<div>
		<ul class="module-attr-tab">
			<li>组件设置</li>
			<span>轮播广告</span>
		</ul>
		<!--  -->
		<div class="duo_swiper_flex">
			<p>轮播间隔(s)：</p>
			<input type="number" placeholder="请输入数字，填0则不轮播" min="0" name="number" max="10" v-model="attr.bannerSecond"/>
		</div>
		<div class="duo_swiper_flex">
			<p>行高(px)：</p>
			<input type="number" placeholder="单位px" min="0" name="number" max="10" v-model="attr.bannerHeight"/>
		</div>
		<div class="duo_swiper_flex" style="margin-bottom:10px;">
			<p>模式：</p>
			<div class="duo_swiper_input"> 
				<div class="swiper_dv" @click="attr.jumpType=3">
					<div class="swiper_img" :class="attr.jumpType == 3 ? 'on' : ''"><img src="../assets/gou.png" alt=""></div>
					<span class="swiper_tip">自定义模式</span>
				</div>
				<div class="swiper_dv" @click="attr.jumpType=2">
					<div class="swiper_img" :class="attr.jumpType == 2 ? 'on' : ''"><img src="../assets/gou.png" alt=""></div>
					<span class="swiper_tip">企业会员模式</span>
				</div>
			</div>
		</div>
		<!--基础设置-->
		<div class="att-con-box" v-if="attr.jumpType == 3">
			<div class="attr-nav-item" v-for="(item, index) in attr.banners" :key="index">
				<div class="ht">
					<div class="t" :class="item.listShow ? 'on' : ''" @click="$util.openAttr(attr.banners,index)">
						轮播图片{{ index + 1 }}
						<span class="el-icon-arrow-down"></span>
					</div>
					<div class="opt">
						<el-button type="primary" v-if="index != 0" @click="up(attr.banners, index)" icon="el-icon-arrow-up" circle></el-button>
						<el-button type="primary" v-if="index != attr.banners.length - 1" @click="down(attr.banners, index)" icon="el-icon-arrow-down" circle></el-button>
						<el-button type="danger" @click="bannerDel(index)" icon="el-icon-delete" circle></el-button>
					</div>
				</div>
				<transition name="down">
					<div class="hm" v-show="item.listShow">
						<AttrImg :item="item" />
						<AttrLink :ref="'link_'+index" :link="item" />
					</div>
				</transition>
			</div>
			<div class="attr-nav-add" v-if="attr.banners.length < 10" @click="addNavList">
				<p class="nav_add_btn">添加图片</p>
				最多添加10张
			</div>
		</div>
		<el-button class="link_fix_btn" type="primary" round @click="swiperSave">提交</el-button>
	</div>
</template>

<script>
import AttrText from './text.vue';
import AttrImg from './img.vue';
import AttrLink from './link.vue';
import AttrBase from './base.vue';				// 多余组件
import module from '../util/module.js';
export default {
	name: 'AttrDuo',
	props: ['attr'],
	components: { AttrText, AttrImg, AttrLink, AttrBase },
	data() {
		return {};
	},
	created(){},
	methods: {
		//添加导航
		addNavList() {
			this.$util.addItem(this.attr.banners,module.moduleDuo);
		},
		//上移
		up(arr, index) {
			arr.splice(index - 1, 0, arr[index]);
			arr.splice(index + 1, 1);
		},
		//下移
		down(arr, index) {
			arr.splice(index + 2, 0, arr[index]);
			arr.splice(index, 1);
		},
		// 确定按钮获取页面数据
		swiperSave(){
			let that = this;
			if(that.attr.bannerSecond == '' || that.attr.bannerSecond < 0){
				that.$message.warning('轮播间隔不能为空且大于0');
				return;
			}else if(that.attr.bannerHeight == '' || that.attr.bannerHeight < 0){
				that.$message.warning('图片行高不能为空且大于0');
				return;
			}else{
				if(that.attr.jumpType ==  3){
					for(var i = 0; i < that.attr.banners.length; i++){
						if(that.attr.banners[i].imgUrl == ''){
							that.$message.warning('轮播广告模块【轮播图片' + (i+1) + '】的图片不能为空');
							return;
						}else{
							if(that.$refs[`link_${i}`][0].linkType == true){
								if(that.attr.banners[i].jumpUrl == ''){
									that.$message.warning('轮播广告模块【轮播图片' + (i+1) + '】设置跳转外链类型的外链地址不能为空');
									return;
								}else{
									that.attr.banners[i].appId = '';
									that.attr.banners[i].indexUrl = '';
								}
							}else{
								if(that.attr.banners[i].appId == ''){
									that.$message.warning('轮播广告模块【轮播图片' + (i+1) + '】设置跳转小程序类型的APPID不能为空');
									return;
								}else if(that.attr.banners[i].indexUrl == ''){
									that.$message.warning('轮播广告模块【轮播图片' + (i+1) + '】设置跳转小程序类型的首页地址不能为空');
									return;
								}else{
									that.attr.banners[i].jumpUrl = '';
								}
							}
						}
					}
				}
			}
			that.$message.success('该模块已提交');
		},

		bannerDel(index){
			if(this.attr.banners.length > 1){
				this.attr.banners.splice(index, 1);
			}else{
				this.$message.error('banner最少为1个');
			}
		}
	}
};
</script>

<style>
.duo_swiper_flex{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;
	padding: 10px 12px;
}
.duo_swiper_flex p{
	font-size: 13px;
	color: #555;
	width: 85px;
	text-align: right;
}
.duo_swiper_flex input{
    padding: 6px 10px;
    color: #555;
    border: 1px solid #eee;
    border-radius: 2px;
    width: 180px;
    flex: 1;
}
.duo_swiper_input{
	display: flex;
	align-items: center;
}
.sp1 {
	display: flex;
	min-width: 70px;
	align-items: center;
	margin-right: 20px;
	cursor: pointer;
}
/* .tit {
	margin-top: -4px;
} */
.el-input {
	flex: 1;
}
/* .el-input__inner {
	height: 29px;
	line-height: 29px;
}
.el-input__icon {
	line-height: 29px;
} */
.swiper_dv{
	display: flex;
	min-width: 70px;
	align-items: center;
	margin-right: 20px;
	cursor: pointer;
}
.swiper_img{
	width: 16px;
	height: 16px;
	box-sizing: border-box;
	border: 1px solid #909090;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 4px;
	}
.swiper_img img{
	width: 10px;
	height: 10px;
}
.swiper_img.on{
	background-color: #409EFF;
	border-color: #409EFF;
}
.swiper_tip{
	font-size: 14px;
	color: #333;
}
</style>