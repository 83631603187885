<template>
	<div class="module-item-tuijian">
		<div class="item-tuijian-con">
			<!--样式一-->
			<div class="item-tuijian-style1">
				<div class="item" v-for="(item,index) in module" @click="$util.openAttr(module,index)" :key="index">
					<span class="tuijian_title" v-if="item.articleTitle.length > 0">{{item.articleTitle}}</span>
					<span class="tuijian_title red" v-else>点击编辑文章标题</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moduleBg from './bg.vue';
import moduleText from './text.vue';
export default {
	props: ['module'],
	components: { moduleBg, moduleText },
	data() {
		return {};
	},
	created(){},
	mounted() {},
	methods: {		 
	}
};
</script>

<style>
.item-tuijian-style1{
	background-color: #F5F5F5;
	box-sizing: border-box;
}
.tuijian_title{
	font-size: 12px;
	box-sizing: border-box;
	margin-top: 10px;
	display: block;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp:1;
	overflow: hidden;
	color: #000;
}
.red{
	color: #808080;
}
</style>
