<template>
	<div class="module-item-duo">
		<!-- <moduleTitle :title="module.title" v-if="module.title.t.s" /> -->
		<div class="item-duo-con">
			<div class="item-duo-style4">
				<swiper class="item"  :options="swiperOption" :style="{height:bannerHeight+'px'}">
					<swiper-slide v-for="(item, index) in module.banners" :key="index">
						<img :src="item.imgUrl.length > 0 ? item.imgUrl : $util.icon('img.jpg')"  :style="{height:bannerHeight+'px'}"/>
					</swiper-slide>
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</div>
		</div>
	</div>
</template>
<script>
import moduleBg from './bg.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
let that = null;
export default {
	props: ['module'],
	components: { moduleBg, Swiper, SwiperSlide },
	data() {
		return {
			swiperOption: {
				autoplay: {
					disableOnInteraction: false,
					delay: 1500,
				},
				paginationClickable: true,
				speed:500,	//切换速度，即slider自动滑动开始到结束的时间（单位ms），也是触摸滑动时释放至贴合的时间。
				loop:true,	// 循环切换
				autoplayDisableOnInteraction:false,		//用户操作swiper之后，是否禁止autoplay.默认为true：停止。
				preventLinksPropagation:true,		//拖动释放时不会输出信息，阻止click冒泡。拖动Swiper时阻止click事件。
				pagination: {
					el: '.swiper-pagination'
				},
				on:{
					click:function(e){
						that.$util.openAttr(that.module.banners,e.clickedIndex);
					}
				}
			},
			bannerHeight:300
		};
	},
	
	created(){
		that = this;
		that.swiperOption.autoplay.delay = that.module.bannerSecond > 0 ? Number(that.module.bannerSecond) * 1000 : 10000 * 1000;
		that.bannerHeight = that.module != null && that.module.bannerHeight != null ? that.module.bannerHeight : 300;
	},

	methods: {		
	}
};
</script>

<style>
.item-duo-con{
	background-color: white;
}
.item-duo-style4 {
	position: relative;
	z-index: 1;
 }
.swiper-pagination-bullet{
	width: 15px !important;
	height: 3px !important;
	border-radius: 0 !important;
}
</style>