<template>
	 <div class="xcx-title">
	 	<p class="tit header_title" >首页</p>
	 	<img src="../assets/xcx.png" alt="" />
	 </div>
</template>

<script>	
	export default{
		 props:{
		 },
		 data(){
			 return{
			 }
		 },
		 
		computed: {
			fhstyle() {
				if (this.title.fh.d) {
					let style = `font-size:${this.title.fh.f}px;color:${this.title.fh.c};`;
					if (this.title.fh.b) {
						style += 'font-weight:bold;';
					}
					if (this.title.fh.i) {
						style += 'font-style:italic;';
					}
					if (this.title.fh.u) {
						style += 'text-decoration:underline;';
					}
					return style;
				}
			}
		},
		
	}
</script>

<style>
.xcx-title{
	background-color: #333333 !important;
}
.header_title{
	color: white !important;
}
</style>
