<template>
	<div>
		<ul class="module-attr-tab">
			<li>组件设置</li>
			<span>富文本</span>
		</ul>
		<!--基础设置-->
		<div class="att-con-box">
			<div class="attr-nav-item">
				<transition name="down">
                    <avue-ueditor v-model="attr.textInfo" :options="options" ></avue-ueditor>
				</transition>
			</div>
		</div>
		<el-button class="link_fix_btn" type="primary" round @click="navSave">提交</el-button>
	</div>
</template>

<script>
import AttrText from './text.vue';
import AttrIcon from './icon.vue';

export default {
	name: 'AttrArticle',
	props: ['attr'],
	components: { AttrText, AttrIcon},
	data() {
		return {
			options: {
				action:  "api/web/tool/file/upload",
				headers: {
					Authorization: this.$store.state.user.Token
				},
				customConfig: {},
				props: {
					url: "path",
					res: "data",
					name: 'path',
				}
            },
		};
	},

	created(){},

	methods: {
		// 确定按钮获取页面数据
		navSave(){
			let that = this;
			if(that.attr.textInfo.length == ''){
				that.$message.warning('富文本内容不能为空');
				return;
			}
			that.$message.success('该模块已提交');
		},
	}
};
</script>

<style>
.att-con-box{
	box-sizing: border-box;
	padding-bottom: 100px;
}
.nav_add_btn{
	background-color: #46A1FF;
	color: white;
	box-sizing: border-box;
	padding: 5px 10px;
	font-size: 12px;
	text-align: center;
	border-radius: 4px;
	margin-right: 5px;
}
.link_fix_btn{
	position: fixed;
	bottom: 20px;
	z-index: 999;
	right: 100px;
	width: 200px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	color: white;
	border-radius: 50px;
	text-align: center;
	cursor: pointer;
	border: none;
	padding: 0 !important;
}
</style>